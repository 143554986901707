import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询车间管理列表
export function listWorkSpace(query: any) {
  return request({
    url: "/factory/workSpace/list",
    method: "get",
    params: query,
  });
}

// 查询车间管理详细
export function getWorkSpace(query: any) {
  return request({
    url: "/factory/workSpace/getInfo",
    method: "get",
    params: query,
  });
}

// 新增车间管理
export function addWorkSpace(data: any) {
  return request({
    url: "/factory/workSpace",
    method: "post",
    data: data,
  });
}

// 修改车间管理
export function updateWorkSpace(data: any) {
  return request({
    url: "/factory/workSpace",
    method: "put",
    data: data,
  });
}

// 删除车间管理
export function delWorkSpace(data: any) {
  return request({
    url: "/factory/workSpace/removes",
    method: "delete",
    data: data,
  });
}

// 所属组织
export function getOrganize() {
  return request({
    url: "/system/dept/treeselect",
    method: "get",
  });
}

// 车间的下拉选择
export function spaceSelectList() {
  return request({
    url: "/factory/workSpace/selectList",
    method: "get",
  });
}

// 根据deptId获取名称
// export function organizeSelectList() {
//   return request({
//     url: "/system/dept/selectList",
//     method: "get",
//   });
// }
export function organizeSelectList() {
  return request({
    url: "/system/dept/list",
    method: "get",
  });
}
