import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;
// 查询产品管理列表
export function listWorkProduct(query: any) {
  return request({
    url: "/factory/workProduct/list",
    method: "get",
    params: query,
  });
}

// 查询产品管理详细
export function getWorkProduct(query: any) {
  return request({
    url: "/factory/workProduct/getInfo",
    method: "get",
    params: query,
  });
}

// 新增产品管理
export function addWorkProduct(data: any) {
  return request({
    url: "/factory/workProduct",
    method: "post",
    data: data,
  });
}

// 修改产品管理
export function updateWorkProduct(data: any) {
  return request({
    url: "/factory/workProduct",
    method: "put",
    data: data,
  });
}

// 删除产品管理
export function delWorkProduct(data: any) {
  return request({
    url: "/factory/workProduct/removes",
    method: "delete",
    data: data,
  });
}

export function selectList() {
  return request({
    url: "/factory/workProduct/selectList",
    method: "get",
  });
}
