import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;
// 查询班次管理列表

// 下拉列表
export function userSelectList() {
  return request({
    url: "/system/user/list",
    method: "get",
  });
}
