import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;
// 查询模具类型列表
export function listModelType(query: any) {
  return request({
    url: "/factory/modelType/list",
    method: "get",
    params: query,
  });
}

// 查询模具类型详细
export function getModelType(query: any) {
  return request({
    url: "/factory/modelType/getInfo",
    method: "get",
    params: query,
  });
}

// 新增模具类型
export function addModelType(data: any) {
  return request({
    url: "/factory/modelType",
    method: "post",
    data: data,
  });
}

// 修改模具类型
export function updateModelType(data: any) {
  return request({
    url: "/factory/modelType",
    method: "put",
    data: data,
  });
}

// 删除模具类型
export function delModelType(data: any) {
  return request({
    url: "/factory/modelType/removes",
    method: "delete",
    data: data,
  });
}

// 模具下拉
export function modelSelectList() {
  return request({
    url: "/factory/modelType/selectList",
    method: "get",
  });
}
